var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0423d02"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN =
    process.env.NEXT_PUBLIC_ENJIN_SENTRY_DSN ||
    process.env.SENTRY_DSN ||
    process.env.NEXT_PUBLIC_SENTRY_DSN;
const SENTRY_ENVIRONMENT =
    process.env.NEXT_PUBLIC_ENJIN_SENTRY_ENVIRONMENT || "unknown";

const tracePropagationTargets = [];
if (process.env.NEXT_PUBLIC_ENJIN_PELANGGAN_PROKSI_API_URL) {
    const apiURL = new URL(
        process.env.NEXT_PUBLIC_ENJIN_PELANGGAN_PROKSI_API_URL,
    );
    tracePropagationTargets.push(apiURL.hostname);
}

Sentry.init({
    dsn: SENTRY_DSN,

    // Session Replay Configs
    // Set Session Sample Rate to 0, use replay.start() if needed.
    // See: https://docs.sentry.io/platforms/javascript/session-replay/understanding-sessions/#manually-starting-replay
    replaysSessionSampleRate: 0,

    // Always enable for Error Sampling, but control it using beforeErrorSampling() below.
    replaysOnErrorSampleRate: 1.0,

    // debug: true,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.05,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    environment: SENTRY_ENVIRONMENT,
    integrations: [
        // Keep transaction alive long enough for spans from API requests
        // to go through.
        Sentry.browserTracingIntegration({ idleTimeout: 3000 }),
        Sentry.replayCanvasIntegration(),

        Sentry.replayIntegration({
            // Allow us to capture information about this endpoint.
            // See: https://docs.sentry.io/platforms/javascript/guides/nextjs/session-replay/configuration/#general-integration-configuration
            networkDetailAllowUrls: [
                "http://localhost:3010/graphql",
                "https://enjin-proksi-development.courtsite.dev/graphql",
                "https://enjin-api.courtsite.my/graphql",
            ],
            networkRequestHeaders: [
                "x-operation-name",
                "sentry-trace",
                "baggage",
            ],
            networkResponseHeaders: ["baggage"],
            maskAllText: false,
            maskAllInputs: false,
            blockAllMedia: false,

            // Last place to modify and decide if we want to send or discard this Error Event
            // Session Replay uses this and we can customize it.
            beforeErrorSampling: (error) => {
                // Check if the error contains our custom setExtra() called from frontend, that includes the field:
                // { "IsSessionReplayRecorded": "yes" }
                // returns True and sends the event if correct.
                return error.extra?.isSessionReplayRecorded?.includes("yes");
            },
        }),
    ],
    // ignoreErrors need to match "title" field in JSON body
    // https://github.com/getsentry/sentry/issues/48997#issuecomment-1548172486
    ignoreErrors: [
        // Not actionable items, e.g PWA issues on iOS
        "TypeError: Internal error",
        "TypeError: Failed to fetch",

        // Firebase auth errors, currently only ignoring network-related errors.
        "Firebase: Error (auth/network-request-failed).",

        // Items not planning to be actioned on, e.g that may resolve itself when we update NextJS version.
        // TODO: Remove them when we do dependency upgrade for NextJS
        "SyntaxError: Unexpected token '.",
        "SyntaxError: Unexpected token '?'",
        "SyntaxError: Unexpected token =",

        // New error seems to surface from Google Search App (GSA)
        // Happens on every page load, but doesnt have impact on users
        // Error started around 8 Feb 2025
        // TODO: Ignore this for now and remove it at a later date to test again.
        "ReferenceError: Can't find variable: gmo",

        // Happens when Service Worker fail to load on browser
        "Error: Rejected",
        "TypeError: Script https://www.courtsite.my/service-worker.js load failed",
        "TypeError: Failed to register a ServiceWorker for scope",
        "SecurityError: Script https://www.courtsite.my/service-worker.js load failed",
        "Error: AbortError: Failed to register a ServiceWorker for scope ('https://www.courtsite.my/') with scrip...",

        // Razorpay checkout script error: https://checkout.razorpay.com/v1/checkout.js
        'TypeError: this["on"+t] is not a function. (In \'this["on"+t](n)\', \'this["on"+t]\' is undefined)',
        'TypeError: this[("on" + t)] is not a function',

        "QuotaExceededError: Quota exceeded.",
        "ResizeObserver loop limit exceeded",
        "ResizeObserver loop completed with undelivered notifications.",
        "TypeError: Network request failed",
        "TypeError: Load failed",
        // Happens when user clicks the stop loading button on the browser while something is loading
        "TypeError: cancelled",
        "Error: NetworkError",
        "Error: Network Error",
        "Error: A network error",
        // Happens when user clicks the stop loading button on the browser while something is loading
        "Error: AbortError: Aborted",
        "ApolloError: Failed to fetch",
        "ApolloError: Load failed",
        "ApolloError: A network error",
    ],
    denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
});
